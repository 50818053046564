import { appDefaultLanguage } from "../../config";
import { ContentType, request } from "../api";
import { baseParams } from "../common";

export const getDocument = async (value, language = appDefaultLanguage, params = {}) =>
  request({
    path: "/document",
    method: "POST",
    body: { ...baseParams, ...value, language },
    type: ContentType.Json,
    format: "json",
    ...params,
  });
